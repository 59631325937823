<template>
  <svg viewBox="0 0 8 12" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M5.09354 1.833C5.71189 1.95823 6.21917 2.2118 6.65457 2.57679C6.77799 2.68024 6.79259 2.86652 6.68825
    2.9892L6.15687 3.61395C6.05806 3.73013 5.88668 3.74977 5.76312 3.66033C5.28486 3.31409 4.78915 3.14895 4.21997
    3.14895C3.56614 3.14895 3.15448 3.42746 3.15448 3.92386C3.15448 4.43236 3.45718 4.63822 4.70433 5.02569C6.22993
    5.51005 7.10176 6.15174 7.10176 7.62896C7.10176 8.8004 6.36094 9.69955 5.07577 10.037C4.94895 10.0703 4.86169
    10.1867 4.86169 10.3179V11.7082C4.86169 11.8694 4.73105 12 4.56992 12H3.76104C3.59989 12 3.46927 11.8694 3.46927
    11.7082V10.4214C3.46927 10.2764 3.36279 10.1518 3.21898 10.1329C2.30616 10.0131 1.59678 9.6673 1.06524
    9.22356C0.938652 9.11788 0.833675 9.03039 0.944652 8.90842L1.67091 8.11042C1.83087 7.93466 1.9081 8.02215 2.02784
    8.11475C2.54794 8.51697 3.13845 8.74295 3.83253 8.74295C4.61954 8.74295 5.0918 8.37974 5.0918 7.77425C5.0918 7.1931
    4.80113 6.92668 3.63879 6.56347C1.82257 6.00648 1.18081 5.2558 1.18081 4.06915C1.18081 2.90428 2.01021 2.08409
    3.24235 1.82194C3.37543 1.79362 3.46927 1.82644 3.46927 1.69036V0.12855C3.46927 -0.0326069 3.59991 0.000744745
    3.76104 0.000744745H4.56992C4.73107 0.000744745 4.86169 0.131386 4.86169 0.292519V1.54826C4.86169 1.68612 4.95842
    1.80565 5.09354 1.833Z" fill="#FEFFFE"/>
  </svg>
</template>
