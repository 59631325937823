
import { Options, Vue } from 'vue-class-component';

import Logo from '@/common/Logo.vue';

import IconsSocial from '@/icons/other/Social.vue';
import IconsService from '@/icons/Service.vue';
import IconDollar from '@/icons/other/Dollar.vue';

import { socialLinks } from '@/settings';

@Options({
  components: {
    Logo,
    IconsSocial,
    IconsService,
    IconDollar,
  },
})
export default class AuthWrapper extends Vue {
  public currentYear = new Date().getUTCFullYear();

  public links = socialLinks;
}
