
import { Options, Vue } from 'vue-class-component';

import facebook from '@/icons/social/Facebook.vue';
import linkedIn from '@/icons/social/LinkedIn.vue';
import twitter from '@/icons/social/Twitter.vue';

@Options({
  components: {
    facebook,
    linkedIn,
    twitter,
  },
  props: {
    social: String,
  },
})
export default class IconSocial extends Vue {

}
