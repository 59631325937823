<template>
<svg viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path d="M9.9999 0.400024C4.6979 0.400024 0.399902 4.69802 0.399902 10C0.399902 15.302 4.6979 19.6 9.9999
  19.6C15.3019 19.6 19.5999 15.302 19.5999 10C19.5999 4.69802 15.3019 0.400024 9.9999 0.400024ZM13.9049 8.26402C13.9089
  8.34602 13.9099 8.42803 13.9099 8.50803C13.9099 11.008 12.0089 13.889 8.5309 13.889C7.5032 13.8907 6.49687 13.5956
  5.6329 13.039C5.7799 13.057 5.9309 13.064 6.0839 13.064C6.9699 13.064 7.7849 12.763 8.4319 12.255C8.03762 12.2473
  7.65557 12.1167 7.33901 11.8815C7.02245 11.6464 6.78715 11.3183 6.6659 10.943C6.94906 10.9969 7.24075 10.9856 7.5189
  10.91C7.09095 10.8235 6.7061 10.5916 6.42961 10.2537C6.15312 9.91578 6.00201 9.49264 6.0019 9.05602V9.03302C6.2569
  9.17402 6.5489 9.26003 6.8589 9.27003C6.4577 9.00295 6.17369 8.5927 6.06495 8.12316C5.95621 7.65362 6.03095 7.16028
  6.2739 6.74402C6.74886 7.32802 7.34116 7.80577 8.01247 8.14634C8.68378 8.4869 9.41913 8.68269 10.1709 8.72102C10.0753
  8.31533 10.1165 7.88943 10.2879 7.50953C10.4594 7.12963 10.7515 6.81701 11.119 6.62029C11.4864 6.42356 11.9086
  6.35375 12.3198 6.4217C12.731 6.48965 13.1083 6.69155 13.3929 6.99602C13.8161 6.9123 14.2219 6.75708 14.5929
  6.53702C14.4519 6.97516 14.1566 7.3472 13.7619 7.58402C14.1367 7.53888 14.5028 7.4381 14.8479 7.28502C14.5944
  7.66492 14.275 7.99646 13.9049 8.26402Z" fill="#383B53"/>
</svg>
</template>
